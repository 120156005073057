import Header from "../Landing component/Header";
import Main from "../Landing component/Main";
import TokenP from "../Landing component/TokenP";
import About from "../Landing component/About";
import Service from "../Landing component/Service";
import SectionMid from "../Landing component/SectionMid";
import OurToken from "../Landing component/OurToken";
import Roadmap from "../Landing component/Roadmap";
import Faq from "../Landing component/Faq";
import Contact from "../Landing component/Contact";
import Footer from "../Landing component/Footer";
import React, { useEffect, useState } from "react";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashHeader from "../DashHeader/DashHeader";
import Modal from "../Modal/Modal";


const Landing = () => {
 
  // export default function Landing() {

  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = new useState({
    cols: [
      { Header: "Sr.No", accessor: "sr" },
      { Header: "Wallet Address", accessor: "uid" },
      { Header: "Amount", accessor: "amount" },
      // { Header: "Txn", accessor: "txn" },
      { Header: " Date", accessor: "txn" },
    ],
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userId, jwtToken } = useSelector((state) => state.Auth);

  const Landing = async () => {
    try {
      let responce = await API?.post(
        `/PlatformRecentActivity`,
        {
          uid: "",
        },
        {
          headers: {
            Authorization: `${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("withdrawalHistory", responce);
      responce = responce?.data?.data[0];
      let arr = [];
      responce.forEach((item, index) => {
        arr.push({
          sr: item.row,
          uid: `${item.uid.slice(0, 4) + "..." + item.uid.slice(38, 42)}`,
          top_update: item?.top_update,
          amount: item?.Amount,
          txn: (
            <a
              className="link-light"
              href={`https://skhscan.com/tx/${item?.txnid}`}
              target="_blank"
              style={{ whiteSpace: "nowrap" }}
              rel="noreferrer"
            >
              <i className="fa fa-pencil-square-o" aria-hidden="true" />{" "}
              {item?.top_update}
            </a>
          ),
        });
      });

      setreferralApi(arr);
    } catch (e) {
      console.log("Error While calling Referrer API", e);
      if (
        e.response.data.showableMessage ===
        "Please login to access this resource"
      ) {
        dispatch(
          updateAuth({
            AinuAuth: false,
            userId: null,
            jwtToken: null,
            ipAddress: null,
          })
        );
        navigate("/");
      }
    }
  };
  
  const [dash, setdataArray] = useState([]);
  async function DashboardAPI() {
    try {
      let responce = await API?.get(`GetCycledetails`);
      responce = responce.data.data[0][0];
      console.log("Dash res-->", responce);

      setdataArray(responce);
    } catch (e) {
      console.log("Something Error", e);
    }
  }
  useEffect(() => {
    DashboardAPI();
  }, []);

  useEffect(() => {
    Landing();
    window.addEventListener("scroll", handleScroll);

    return () => {
      
      <div>
        <DashHeader />
      </div>;
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {};

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const copyToClipboard = () => {
    const contractAddress = document.getElementById("contract-address").innerText;
    navigator.clipboard.writeText(contractAddress).then(() => {
      alert("Contract address copied to clipboard!");
    }).catch(err => {
      console.error("Failed to copy: ", err);
    });}
    
  return (
   
    <>
    
      <Header />
      {/* ======================banner section start========================== */}
      <Main />
      <Modal />
      {/* <TokenP />  */}
      {/* <About /> */}
      {/* <Service /> */}

      {/* <SectionMid /> */}
      {/* <OurToken /> */}

      {/* <Roadmap /> */}
      {/* <Faq /> 
      <Contact /> */}
      {/* scroll strat============  */}
      <div className="container ">
        <div className="row">
          <div className="col-md-6">
            <h2 className="headingmain">STAKING CONDITIONS</h2>
            <p className="para">
              - Minimum Staking- 10BBT<br></br>- One ticket will generate for
              one wallet<br></br>- Users can buy multiple tickets via multiple
              wallets.<br></br>- Lucky Draw will start after the staking of
              1-Lac BBT.<br></br>- Draw Prize<br></br>
              1. TATA NEXON CAR. <br></br>
              2. BMW SUPER SPORTS BIKE<br></br>
              3. I-PHONE 15 PRO MAX.
            </p>
          </div>
          <div className="col-md-6">
            <div className="mt-3">
              <center>
                <img src="assets/image/logos.png" width="60%" alt="" />
              </center>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-md-5 py-3 mt-4 mt-sm-0">
        <div className="row">
          <div className="col-12 mb-3 text-center">
            <h4 className="fw-bold text-white">Block-Chain</h4>
          </div>
          <div className="col-12">
            <div className="image">
              <img
                src="assets/image/how_to_import.png"
                alt=""
                className="w-100 h-100"
              />
            </div>
          </div>
          <div className="col-12 mt-5 text-center copytext-sec">
            <p className="">
              BBT CONTRACT ADDRESS{" "}
              <div className="address" id="contract-address">
                0x6928ca6d72966c21c48268c4f9e836c5f52b64b4
              </div>
            </p>
            
            <button
              className="btn bg-primary text-white fw-bold fs-6 py-3 px-4"
              onClick={copyToClipboard}
            >
              Copy
            </button>
          </div>
        </div>
      </div>
      <div className="container-fluid pb-md-5 py-3 mt-4 mt-sm-0">
        <div className="row">
          <div className="col-12 mb-3 text-center">
            <h4 className="fw-bold text-white">Buy Token Form</h4>
          </div>
          <div className="col-12">
            <div className="image">
              <img
                src="assets/image/how_to{swap.png"
                alt=""
                className="w-100 h-100"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-100">
        <div className="row">
          <div className="col-md-3">
            <div className="magicbtn2 fw-bold px-4 py-3 text-white rounded-3">
              <div className="dashfl">
                <div className="">
                  <span>Current Cycle</span>
                  <br />
                  <span>{dash?.CurrentCycle}</span>
                </div>
                <div className="useft">
                  <i className="fa fa-snowflake-o" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="magicbtn2 fw-bold px-4 py-3 text-white rounded-3">
              <div className="dashfl">
                <div className="">
                  <span>Cycle Limit</span>
                  <br />
                  <span>{dash?.CycleLimit} BBT</span>
                </div>
                <div className="useft">
                  <i className="fa fa-television" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div
              href="#"
              className="magicbtn2 fw-bold  px-4 py-3 text-white rounded-3"
            >
              <div className="dashfl">
                <div className="">
                  <span>Total Stake</span>
                  <br />
                  <span>{dash?.TotalStakeBBT} BBT</span>
                </div>
                <div className="useft">
                  <i className="fa fa-space-shuttle" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="magicbtn2 fw-bold  px-4 py-3 text-white rounded-3">
              <div className="dashfl">
                <div className="">
                  <span>Balance Stake</span>
                  <br />
                  <span>{dash?.RemainStakeBBT_1} BBT</span>
                </div>
                <div className="useft">
                  <i className="fa fa-shopping-bag" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="card partnrPdd">
                <div className="card-body">
                  <h4 className="card-title colow mb-4">
                    Platform Recent Activity
                  </h4>
                  <div className="table-responsive">
                    <div className="table table-responsive table-striped">
                      <Table
                        data={[...currentPost]}
                        columns={matching_income.cols}
                      />
                      <Table_Buttons
                        setcurrentPage={setcurrentPage}
                        currentPage={currentPage}
                        totalData={referralApi.length}
                        listPerpage={listPerpage}
                        handleRowsPerPageChange={setlistPerpage}
                      />
                    </div>
                    {/* <table className="table align-middle table-nowrap mb-0">
                    <tbody>
                      <tr>
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-user-plus tbe" aria-hidden="true" />
                        </td>
                        <td style={{ color: "#fff" }}>new user joined</td>
                        <td style={{ color: "#fff" }}>
                          <span className="isd">(ID 6558)</span>
                        </td>
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }}  className="text-white">
                          <i className="fa fa-pencil-square-o" aria-hidden="true" />
                          &nbsp;{" "}
                          <a
                            href="https://bscscan.com/tx/0x575c429e656f2eb82e31a0b6056d1d120b5c2bc5c7b997f9d79a575026eef4f7"
                            target="_blank"
                            className="text-white"
                            rel="noopener noreferrer"
                          >
                            10/09/2024, 01:38 PM
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-briefcase tbe1" aria-hidden="true" />
                        </td>
                        <td style={{ color: "#fff" }}>
                          <span className="isd">(ID 4425)</span>
                        </td>
                        <td style={{ color: "#fff" }}>
                          + 10 USDT in <span className="acolor">P5</span>
                        </td>
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-pencil-square-o" aria-hidden="true" />
                          &nbsp;{" "}
                          <a
                            href="https://bscscan.com/tx/0x679be44b91d568e17c9b9497074ec51b30618de71c664703a3863ac5e27a1519"
                            target="_blank"
                            rel="noopener noreferrer"
                             className="text-white"
                          >
                            10/09/2024, 01:38 PM
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-user-plus tbe" aria-hidden="true" />
                        </td>
                        <td style={{ color: "#fff" }}>new user joined</td>
                        <td style={{ color: "#fff" }}>
                          <span className="isd">(ID 6557)</span>
                        </td>
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-pencil-square-o" aria-hidden="true" />
                          &nbsp;{" "}
                          <a
                            href="https://bscscan.com/tx/0x0441ed226ba15d4c86d9542f495b7bd1353b2b6a00aefa14646cd714ee658027"
                            target="_blank"
                            rel="noopener noreferrer"
                             className="text-white"
                          >
                            10/09/2024, 01:36 PM
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-briefcase tbe1" aria-hidden="true" />
                        </td>
                        <td style={{ color: "#fff" }}>
                          <span className="isd">(ID 6553)</span>
                        </td>
                        <td style={{ color: "#fff" }}>
                          + 10 USDT in <span className="acolor">P15</span>
                        </td>
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-pencil-square-o" aria-hidden="true" />
                          &nbsp;{" "}
                          <a
                            href="https://bscscan.com/tx/0xe3b0c2133d4f29aae7add23828ad99f326b34aa8c8a7551faf3176e0942dd3e8"
                            target="_blank"
                            rel="noopener noreferrer"
                             className="text-white"
                          >
                            10/09/2024, 01:35 PM
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-briefcase tbe1" aria-hidden="true" />
                        </td>
                        <td style={{ color: "#fff" }}>
                          <span className="isd">(ID 2933)</span>
                        </td>
                        <td style={{ color: "#fff" }}>
                          + 20 USDT in <span className="acolor">P15</span>
                        </td>
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-pencil-square-o" aria-hidden="true" />
                          &nbsp;{" "}
                          <a
                            href="https://bscscan.com/tx/0x4c50a448bdb30718d84f2f77c84b9f861989f2b8bdba29ffdbaf160cd5a3eca9"
                            target="_blank"
                            rel="noopener noreferrer"
                             className="text-white"
                          >
                            10/09/2024, 01:34 PM
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-user-plus tbe" aria-hidden="true" />
                        </td>
                        <td style={{ color: "#fff" }}>new user joined</td>
                        <td style={{ color: "#fff" }}>
                          <span className="isd">(ID 6556)</span>
                        </td>
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-pencil-square-o" aria-hidden="true" />
                          &nbsp;{" "}
                          <a
                            href="https://bscscan.com/tx/0x374e2790f85dcafdfdf722da84dd4248b2acf8697de7e27ef74c67deb9a7c645"
                            target="_blank"
                            rel="noopener noreferrer"
                             className="text-white"
                          >
                            10/09/2024, 01:33 PM
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-briefcase tbe1" aria-hidden="true" />
                        </td>
                        <td style={{ color: "#fff" }}>
                          <span className="isd">(ID 6553)</span>
                        </td>
                        <td style={{ color: "#fff" }}>
                          + 10 USDT in <span className="acolor">P5</span>
                        </td>
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-pencil-square-o" aria-hidden="true" />
                          &nbsp;{" "}
                          <a
                            href="https://bscscan.com/tx/0xe5981eee4a5e815986b75275fb8fa89fd5ab4b8a32370aa028c8b99a9fbc4d0b"
                            target="_blank"
                            rel="noopener noreferrer"
                             className="text-white"
                          >
                            10/09/2024, 01:33 PM
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-user-plus tbe" aria-hidden="true" />
                        </td>
                        <td style={{ color: "#fff" }}>new user joined</td>
                        <td style={{ color: "#fff" }}>
                          <span className="isd">(ID 6555)</span>
                        </td>
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-pencil-square-o" aria-hidden="true" />
                          &nbsp;{" "}
                          <a
                            href="https://bscscan.com/tx/0x85a9da18b09f8bce6786cc22d5439ad8884a2d580610d47febb416df1e5883e5"
                            target="_blank"
                            rel="noopener noreferrer"
                             className="text-white"
                          >
                            10/09/2024, 01:33 PM
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-user-plus tbe" aria-hidden="true" />
                        </td>
                        <td style={{ color: "#fff" }}>new user joined</td>
                        <td style={{ color: "#fff" }}>
                          <span className="isd">(ID 6554)</span>
                        </td>
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-pencil-square-o" aria-hidden="true" />
                          &nbsp;{" "}
                          <a
                            href="https://bscscan.com/tx/0xe77097efa476176163ab415aae10420dda33ddee92ebb567c082e563a22e0549"
                            target="_blank"
                            rel="noopener noreferrer"
                             className="text-white"
                          >
                            10/09/2024, 01:30 PM
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-briefcase tbe1" aria-hidden="true" />
                        </td>
                        <td style={{ color: "#fff" }}>
                          <span className="isd">(ID 395)</span>
                        </td>
                        <td style={{ color: "#fff" }}>
                          + 160 USDT in <span className="acolor">P15</span>
                        </td>
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }} />
                        <td style={{ color: "#fff" }}>
                          <i className="fa fa-pencil-square-o" aria-hidden="true" />
                          &nbsp;{" "}
                          <a
                            href="https://bscscan.com/tx/0xee4d79459db5aba0061f0452eba49d870a254af278ab0269808cd3ef531b4681"
                            target="_blank"
                            rel="noopener noreferrer"
                             className="text-white"
                          >
                            10/09/2024, 01:29 PM
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scroll-area">
        <div className="top-wrap">
          <div className="go-top-btn-wraper">
            <div className="go-top go-top-button" onClick={scrollToTop}>
              <i className="fa fa-angle-double-up" aria-hidden="true" />
              <i className="fa fa-angle-double-up" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
      {/* scroll end============  */}
      {/* ======================banner section end========================== */}
      <Footer />
    </>
  );
};
export default Landing;
