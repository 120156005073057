import React, { useEffect, useState } from "react";
import Table from "../Table/Table";
import TableButtons from "../Table_Buttons/Table_Button";
import DashHeader from "../DashHeader/DashHeader";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth } from "../../Redux/AuthSlice";
import { API } from "../../Utility/API";
import toast from "react-hot-toast";

const ClaimHistory = () => {
    const [reportApi, setReportApi] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [listPerPage, setListPerPage] = useState(50);
    const dispatch = useDispatch();
    const { userId, jwtToken } = useSelector((state) => state.Auth);

    const referralAPI = async () => {
        try {
            let response = await API.post(
                `claimedHistory`,
                {
                    uid: userId,
                    fdate: "",
                    tdate: ""
                },
                {
                    headers: {
                        Authorization: `${jwtToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            response = response.data.data[0];
            //console.log("res referral-->", response);
            setReportApi([]);

            let arr = [];
            response.forEach((item, index) => {
                arr.push({
                    sr: index + 1,
                    uid: item.uid,
                    accountnumber: `${item.tokenvalue} BBT`,
                    amount: `$ ${item.Amount}`,
                    utrno: (
                        <a
                            href={`https://skhscan.com/tx/${item.txn}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            View Txn
                        </a>
                    ),
                    requestedDate: item.dd,
                    claimDate: item.cd,
                    staketime: `${item.staketime} Year`,
                    stakerate: `${item.stakerate} % per annum`,
                });
            });

            setReportApi(arr);
        } catch (e) {
            if (
                e.response.data.showableMessage ===
                "Please login to access this resource"
            ) {
                dispatch(
                    updateAuth({
                        isAuth: false,
                        userId: null,
                        jwt_token: null,
                    })
                );
            }
        }
    };


    useEffect(() => {
        referralAPI();
    }, []);

    const indexOfLastPost = currentPage * listPerPage;
    const indexOfFirstPage = indexOfLastPost - listPerPage;
    const currentPost = reportApi.slice(indexOfFirstPage, indexOfLastPost);

    const reportData = {
        cols: [
            { Header: "S.No", accessor: "sr" },
            { Header: "User Id", accessor: "uid" },
            { Header: "Amount", accessor: "amount" },
            //{ Header: "Token(Received)", accessor: "accountnumber" },
            //{ Header: "Txn Hash", accessor: "utrno" },
            { Header: "Stake Time", accessor: "staketime" },
            { Header: "Stake Rate", accessor: "stakerate" },
            { Header: "Stake Date", accessor: "requestedDate" },
            { Header: "Claimed Date", accessor: "claimDate" },
        ],
    };

    return (
        <div>
            <DashHeader />
            <div className="page-wrapper">
                <div className="page-content adjustmentsection">
                    <div className="container-fluid dashboardSection">
                        <div className="container h-100">
                            <div className="row h-100">
                                <div className="col-12 h-100 d-flex align-items-center justify-content-center flex-column">
                                    <h2 className="headingDashboard fw-bold py-3">
                                        <span>Claim History</span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid dashboardSection2">
                        <div className="container">
                            <div className="row gy-4">
                                <div className="col-md-12 mx-auto">
                                    <div className="row mx-0 bottomtable ">
                                        <div className="col-12 border rounded-2 py-3 backgroundcolor">
                                            <div className="table table-responsive table-striped">
                                                <Table
                                                    data={[...currentPost]}
                                                    columns={reportData.cols}
                                                />
                                                <TableButtons
                                                    indexOfFirstPage={indexOfFirstPage}
                                                    indexOfLastPost={indexOfLastPost}
                                                    setCurrentPage={setCurrentPage}
                                                    currentPage={currentPage}
                                                    totalData={reportApi.length}
                                                    listPerPage={listPerPage}
                                                    handleRowsPerPageChange={setListPerPage}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ClaimHistory;
