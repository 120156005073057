import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./Modal.css";
import { Link } from "react-router-dom";
import Popupimage1 from "../Assets/popup.jpg";

const App = () => {
  const [showFirstModal, setShowFirstModal] = useState(true);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [noMoreRemindersToday, setNoMoreRemindersToday] = useState(false);

  useEffect(() => {
    // Check if there's a stored date in localStorage
    const storedDate = localStorage.getItem("modalDate");
    const currentDate = new Date().toLocaleDateString();

    if (storedDate !== currentDate) {
      // If the stored date is not today, reset the modal visibility
      setShowFirstModal(true);
      localStorage.removeItem("modalDate");
      localStorage.removeItem("noMoreRemindersToday");
    } else {
      // If the stored date is today, check if the reminder was disabled
      const noReminders = localStorage.getItem("noMoreRemindersToday");
      setNoMoreRemindersToday(noReminders === "true");
    }
  }, []);

  const handleCloseFirstModal = () => {
    setShowFirstModal(false);
    if (!noMoreRemindersToday) {
      setShowSecondModal(true); // Open the second modal only if not disabled
    }
  };

  const handleCloseSecondModal = () => {
    setShowSecondModal(false);
    // Store today's date in localStorage
    const currentDate = new Date().toLocaleDateString();
    localStorage.setItem("modalDate", currentDate);

    if (noMoreRemindersToday) {
      localStorage.setItem("noMoreRemindersToday", "true");
    }
  };

  const handleCheckboxChange = (event) => {
    setNoMoreRemindersToday(event.target.checked);
  };

  // const handleCloseFirstModal = () => {
  //   setShowFirstModal(false);
  //   setShowSecondModal(true); // Open the second modal after closing the first one
  // };

  // const handleCloseSecondModal = () => {
  //   setShowSecondModal(false);
  // };

  return (
    <div>
      {/* First Modal */}
      <Modal
        className="mainmodal"
        show={showFirstModal}
        onHide={handleCloseFirstModal}
      >
        {/* <Modal.Header className="notic">
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="Scroll">
          <div className="mt-2">
            <div className="text-start">
            <div className="popupimage p-2">
              <img className="w-100 h-100 rounded-2" src={Popupimage1} alt="" />
            </div>
              {/* <p>
                <font color="#000000">🎰🎰</font>
                <font
                  color="#000000"
                  style={{
                    fontFamily:
                      '"Helvetica Neue", Helvetica, Arial, sans-serif',
                    backgroundColor: "rgb(255, 255, 0)",
                  }}
                >
                  Click to enter the channel to receive daily red envelope
                  rewards
                </font>
                <font
                  color="#000000"
                  style={{
                    fontFamily:
                      '"Helvetica Neue", Helvetica, Arial, sans-serif',
                  }}
                >
                  🎁🎁
                </font>
              </p>{" "} */}
            </div>
            {/* <div className="popupimage mb-3 p-2">
              <img className="w-100 h-100 rounded-2" src={Popupimage1} alt="" />
            </div>
            <div className="popupimage p-2">
              <img className="w-100 h-100 rounded-2" src={Popupimage2} alt="" />
            </div> */}
          </div>
          {/* <button>Confirm</button> */}
        </Modal.Body>
        {/* <Modal.Footer className="ftbt">
        <button type="button" class="bt btn"><i class="fa fa-close"></i></button>
          <Button variant="" onClick={handleCloseFirstModal} className="bt">
            Close<i className="fa fa-close"></i>
            {isPopupOpen && <Popup onClose={handleClosePopup} />}
          </Button>
        </Modal.Footer> */}
      </Modal>

      {/* Second Modal */}
      {/* <Modal
        className="secondmodal "
        show={showSecondModal}
        onHide={handleCloseSecondModal}
      >
        <Modal.Header className="extra">
          <Modal.Title className="ex">
            {" "}
            <h6>Extra first deposit bonus</h6>
            <p>Each account can only receive rewards once</p>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="Scroll">
          <div className="card border-0 mx-2">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <p className="mb-0">
                  First deposit<span className="yellowtext">1000000</span>
                </p>
                <div className="orangebtn">+ ₹100000</div>
              </div>
              <div className="description mb-2">
                Deposit 1000000 for the first time and you will receive 100000
                bonus
              </div>
              <div className="foot">
                <div className="step">0/1000000</div>
                <div className="btn n2">Deposit</div>
              </div>
            </div>
          </div>
          <div className="card border-0 mx-2">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <p className="mb-0">
                  First deposit <span className="yellowtext">500000</span>
                </p>
                <div className="orangebtn">+ ₹50000</div>
              </div>
              <div className="description mb-2">
                {" "}
                Deposit 500000 for the first time and you will <br />
                receive 50000 bonus
              </div>
              <div className="foot">
                <div className="step">0/500000</div>
                <div className="btn n2">Deposit</div>
              </div>
            </div>
          </div>
          <div className="card border-0 mx-2">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <p className="mb-0">
                  First deposit <span className="yellowtext">100000</span>
                </p>
                <div className="orangebtn"> + ₹10000.00</div>
              </div>
              <div className="description mb-2">
                Deposit 100000 for the first time and you will <br />
                receive 10000 bonus
              </div>
              <div className="foot">
                <div className="step">0/100000</div>
                <div className="btn n2">Deposit</div>
              </div>
            </div>
          </div>
          <div className="card border-0 mx-2">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <p className="mb-0">
                  First deposit <span className="yellowtext">50000</span>
                </p>
                <div className="orangebtn">+ ₹5000.00</div>
              </div>
              <div className="description mb-2">
                Deposit 50000 for the first time and you will <br />
                receive 5000 bonus
              </div>
              <div className="foot">
                <div className="step">0/50000</div>
                <div className="btn n2">Deposit</div>
              </div>
            </div>
          </div>
          <div className="card border-0 mx-2">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <p className="mb-0">
                  First deposit <span className="yellowtext">10000</span>
                </p>
                <div className="orangebtn">+ ₹1000.00</div>
              </div>
              <div className="description mb-2">
                {" "}
                Deposit 10000 for the first time and you will <br />
                receive 1000 bonus
              </div>
              <div className="foot">
                <div className="step">0/10000</div>
                <div className="btn n2">Deposit</div>
              </div>
            </div>
          </div>
          <div className="card border-0 mx-2">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <p className="mb-0">
                  First deposit <span className="yellowtext">5000</span>
                </p>
                <div className="orangebtn"> + ₹500.00</div>
              </div>
              <div className="description mb-2">
                Deposit 5000 for the first time and you will <br />
                receive 500 bonus
              </div>
              <div className="foot">
                <div className="step">0/5000</div>
                <div className="btn n2">Deposit</div>
              </div>
            </div>
          </div>
          <div className="card border-0 mx-2">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <p className="mb-0">
                  First deposit <span className="yellowtext">200</span>
                </p>
                <div className="orangebtn">+ ₹20.00</div>
              </div>
              <div className="description mb-2">
                Deposit 200 for the first time and you will <br />
                receive 20 bonus
              </div>
              <div className="foot">
                <div className="step orangetext">200/200</div>
                <div className="btn n2 maingridentcolor1 text-white border-0">
                  Receive
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex align-items-center justify-content-between">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input shadow-none"
                type="checkbox"
                id="inlineCheckbox1"
                value="option1"
                checked={noMoreRemindersToday}
                onChange={handleCheckboxChange}
              />
              <label className="form-check-label" for="inlineCheckbox1">
                No more reminders today
              </label>
            </div>
          </div>
          <div className="close ">
            {" "}
            <Link to="/activity">
              <button className="btn text-white roundec-pill fw-bold maingridentcolor1">
                Activity
              </button>
            </Link>{" "}
          </div>
          <div
            style={{ marginLeft: "50%", cursor: "pointer" }}
            onClick={handleCloseSecondModal}
          >
            <i
              className="fa fa-times-circle"
              style={{ fontSize: "x-large", color: "#fe8e84" }}
            ></i>
          </div>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
};

export default App;
